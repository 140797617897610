import React, { Component } from 'react';
import styled from 'styled-components';

const FeatureContainer = styled.section`
  height: 100vh;
  background: black;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/tree-museum.appspot.com/o/band.jpg?alt=media&token=050915dc-86ad-4c67-b3c7-13afc7b42099');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 780px) {
    height: 70vh;
    width: 100%;
  }
  @media (max-width: 580px) {
    height: 50vh;
  }
  @media (max-width: 380px) {
    height: 30vh;
  }
`;

const FeatureBackground = styled.div`
  background: black;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: -1;
  top: 0;
`;

export default class FeatureBanner extends Component {
  render() {
    return (
      <FeatureContainer id="top">
        <div />
        <FeatureBackground />
      </FeatureContainer>
    );
  }
}
