import React from 'react';
import styled from 'styled-components';
import { Link } from '../lib';
import { PostBody } from './PostFull';

const Header = styled.h3`
  font-size: 24px;
  font-weight: 200;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
`;

const Post = ({ post, index }) => {
  const prevLength = 100;

  const previewText =
    post.preview.length > prevLength
      ? post.preview.substr(0, prevLength) + '...'
      : post.preview;

  const postUrl = `/blog/${post.slugUrl}`;

  return (
    <div key={index}>
      <Header>{post.title}</Header>
      <PostBody>{previewText}</PostBody>
      <Link to={postUrl}>Read More</Link>
    </div>
  );
};

export default Post;
