export default {
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    background: 'rgb(22, 22, 22)',
    forecolor: '#fafafa',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: 'Roboto, sans-serif',
    headings: 'Chosence, sans-serif',
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  borders: [0, '1px solid', '2px solid', '4px solid'],
  radii: [0, 4, 8, 12, 16],
  buttons: {
    primary: {
      'font-family': 'Chosence, sans-serif',
      'letter-spacing': '1.6px',
    },
  },
};
