import React, { Component } from 'react';
import styled from 'styled-components';
import { Container, ServiceButton } from '../lib';
import InstagramPosts from './InstagramPosts';

const data = {
  bio:
    'Tree Museum is an indie rock band based out of Toronto, Canada. Uniquely composed of veterans from the Thunder Bay music scene, ' +
    "Tree Museum's polished sound focuses on blending dreamy lush guitars with aggressively dark grooves. " +
    'Known for their sonic tangents and powerful vocals, the group brings waves of intensity to every performance.',
  members: [
    { name: 'Aaron Tocheri', role: 'Vocals, Guitar' },
    { name: 'Adam Houle', role: 'Drums' },
    { name: 'Greg Smith', role: 'Guitar' },
    { name: 'Tarun Dawar', role: 'Bass' },
  ],
};

const sources = [
  {
    name: 'facebook',
    url: 'https://www.facebook.com/treemuseum/',
  },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/tree.museum/',
  },
  {
    name: 'spotify',
    url: 'https://open.spotify.com/artist/2ZUmP24iJ77uZ1xHoz6pzi',
  },
  {
    name: 'youtube',
    url: 'https://www.youtube.com/channel/UCsXe_1v-b6At5aG9FbSLhOg/featured',
  },
];

const Section = styled(Container)`
  padding: 32px 0;
  line-height: 28px;
`;

const HalfContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  > * {
    flex: 1 0 400px;
    align-items: flex-start;
  }

  > *:first-child {
    margin-right: 32px;
  }

  @media (max-width: $mobile-break) {
    > * {
      flex: 1 1 400px;
      margin: auto;
    }

    > *:first-child {
      margin-right: 0;
    }
  }
`;

const H2 = styled.h2`
  padding: 0;
  padding-bottom: 24px;
`;

const H3 = styled.h3`
  padding: 16px 0 4px;
`;

const Bio = styled.p`
  max-width: 600px;
`;

const SocialBox = styled.div`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  > * {
    min-width: 180px;
    flex: 1 1 100px;
    margin: 4px;
  }
`;

const BandMember = ({ name, role }) => (
  <div>
    <span>{name}</span>
    &nbsp; - &nbsp;
    <span>{role}</span>
  </div>
);

class About extends Component {
  render() {
    const { bio, members } = data;

    return (
      <Section id="about">
        <H2>About</H2>
        <HalfContainer>
          <div>
            <H3>Bio</H3>
            <Bio>{bio}</Bio>
            <H3>Members</H3>
            {members.map((member, i) => (
              <BandMember {...member} key={i} />
            ))}
          </div>
          <div>
            <H3>Instagram Feed</H3>
            <InstagramPosts />
          </div>
        </HalfContainer>
        <div>
          <H3>Social</H3>
          <SocialBox>
            {sources.map(({ name, url }, i) => (
              <ServiceButton name={name} url={url} key={i} />
            ))}
          </SocialBox>
        </div>
      </Section>
    );
  }
}

export default About;
