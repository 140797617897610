import React from 'react';
import styled from 'styled-components';
import { compose, defaultProps } from 'recompose';
import { pipe, sort, take, reject, anyPass, propSatisfies } from 'ramda';
import {
  branchShouldRequest,
  withRequest,
  withMocks,
  isNilOrEmpty,
} from '../../../helpers';
import { blogStore } from '../../../core/requestUtil';
import Post from '../../blog/Post';
import { Link as LinkButton, Container } from '../../lib';

const numStories = 3;

const NewsBackground = styled.div`
  background: rgba(50, 100, 100, 0);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 650px) {
    width: 100%;
  }
`;

const NewsWrap = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 7vh;
  margin: 0 auto;
`;

const NewsHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 7vh;
  padding: 2px;
  width: 100%;
  height: 10vh;
`;

const H2 = styled.h2`
  flex: 1 1 auto;
  padding: 18px 32px;
  background: rgba(0, 0, 0, 1);
`;

const NewsInternal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const NewsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const PostWrap = styled.div`
  flex: 1 1 350px;
  margin: 0 2px;
  margin-bottom: 8px;
  padding: 22px 32px;
  background: rgba(0, 0, 0, 1);
  transition: all 0.3s;
`;

const ViewAllButton = styled(LinkButton)`
  margin: 32px;
`;

const News = ({ news }) => (
  <NewsBackground id="news">
    <NewsWrap>
      <NewsHeaderWrap>
        <H2>News</H2>
      </NewsHeaderWrap>
      <NewsInternal>
        <NewsBlock>
          {news.map((post, index) => (
            <PostWrap key={index}>
              <Post post={post} />
            </PostWrap>
          ))}
        </NewsBlock>
      </NewsInternal>
      {news.length > numStories && (
        <ViewAllButton to="/blog">View all news</ViewAllButton>
      )}
    </NewsWrap>
  </NewsBackground>
);

export default compose(
  defaultProps({ news: [] }),
  branchShouldRequest(
    withRequest('news', async () => {
      const news = await blogStore.getAll();
      return pipe(
        reject(
          anyPass([
            propSatisfies(isNilOrEmpty, 'title'),
            propSatisfies(isNilOrEmpty, 'title'),
          ])
        ),
        sort((a, b) => new Date(b.datePosted) - new Date(a.datePosted)),
        take(numStories)
      )(news);
    }),
    withMocks('news', () => require('./mocks.json'))
  )
)(News);
