import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import Routes from './routes';
import theme from './styles/theme';
import GlobalStyles from './styles/global-styles';

console.log('init');

const App = () => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyles />
      <Routes />
    </>
  </ThemeProvider>
);

ReactDOM.render(<App />, document.getElementById('root'));
