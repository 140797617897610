import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconButtonBase } from './IconButton';

const sources = {
  apple: {
    text: 'Apple Music',
    color: '#2d8ad6',
  },
  bandcamp: {
    text: 'Bandcamp',
    color: '#5c8a95',
  },
  soundcloud: {
    text: 'Soundcloud',
    color: '#f06e00',
  },
  spotify: {
    text: 'Spotify',
    color: '#1cae55',
  },
  youtube: {
    text: 'Youtube',
    color: 'rgb(236, 46, 46)',
  },
  'google-play': {
    text: 'Google Play',
    color: '#ef6c00',
  },
  tidal: {
    text: 'Tidal',
    color: '#000a61',
  },
  facebook: {
    text: 'Facebook',
    color: '#5693ff',
  },
  instagram: {
    text: 'Instagram',
    color: 'linear-gradient(-135deg,#1400c8,#b900b4,#f50000)',
  },
};

const mapColor = name => sources[name].color;

const ButtonBase = styled(IconButtonBase).attrs({ as: 'a' })`
  &:hover,
  &:active {
    color: white;
    box-shadow: inset 0 0 0 2px transparent;
    background: ${p => mapColor(p.name)};
  }
`;

const mapIcon = name =>
  ({
    googlePlay: 'fab fa-google-play',
    tidal: 'fa fa-headphones-alt',
  }[name] || 'fab fa-' + name);

export const ServiceButton = ({ name, url, ...rest }) => (
  <ButtonBase
    href={url}
    name={name}
    target="_blank"
    rel="noopener noreferrer"
    {...rest}
  >
    <i className={mapIcon(name)} />
    <span>{sources[name].text}</span>
  </ButtonBase>
);

ServiceButton.propTypes = {
  name: PropTypes.oneOf([
    'apple',
    'spotify',
    'bandcamp',
    'soundcloud',
    'youtube',
    'googlePlay',
  ]),
};
