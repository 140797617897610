import { Link as RebassLink } from 'rebass';
import { compose, defaultProps, branch, renderComponent } from 'recompose';
import { propEq, propSatisfies, complement } from 'ramda';
import { Link as RouterLink } from 'react-router-dom';
import { isNilOrEmpty } from '../../helpers';
import styled, { css } from 'styled-components';
import { buttonStyles } from './Button';

const space = level => p => p.theme.space[level] + 'px';

const buttonLinkStyles = css`
  ${buttonStyles};
  margin-top: ${space(4)};
  padding-right: ${space(3)};
  padding-left: ${space(3)};
  padding-top: ${space(2)};
  padding-bottom: ${space(2)};
  color: ${p => p.theme.colors.forecolor};
  border: ${p => p.theme.borders[2]};
  text-decoration: none;
  border-color: white;
`;

const ButtonLink = styled(RebassLink)`
  ${buttonLinkStyles};
`;

const StyledLink = styled(RebassLink).attrs({})`
  color: rgba(235, 210, 210, 0.8);

  &:hover {
    color: rgba(235, 210, 210, 0.9);
    text-decoration: underline;
  }
`;

const StyledRouterLink = styled(RouterLink)`
  ${buttonLinkStyles};
`;

export const Link = compose(
  defaultProps({
    type: 'button',
  }),
  branch(propEq('type', 'link'), compose(renderComponent(StyledLink))),
  branch(
    propSatisfies(complement(isNilOrEmpty), 'to'),
    renderComponent(StyledRouterLink)
  )
)(ButtonLink);
