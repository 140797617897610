import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ImageCircle } from './image-circle.svg';
import { ReactComponent as HeadphoneUser } from './Orion_headphones-user.svg';

export const ImageCircleIcon = () => <ImageCircle />;

const StyledHeadphoneUser = styled(HeadphoneUser)`
  margin-top: 10px;
  margin-right: 8px;
`;
export const HeadphoneUserIcon = () => <StyledHeadphoneUser />;
