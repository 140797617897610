export const neonDreams = [
  {
    name: 'spotify',
    url: 'https://open.spotify.com/album/3YwZ6F5O7OTyOXiag8X6Ow',
  },
  {
    name: 'apple',
    url:
      'https://music.apple.com/us/album/neon-dreams-f-d-up-friends-single/1665348388',
  },
  {
    name: 'youtube',
    url: 'https://music.youtube.com/watch?v=p57ZZuSa7oM',
  },
  {
    name: 'bandcamp',
    url: 'https://treemuseum.bandcamp.com/track/neon-dreams-fucked-up-friends',
  },
];

export const whereToBegin = [
  {
    name: 'spotify',
    url: 'https://open.spotify.com/album/2xbSav8LXSpeo1UhMAVHeO',
  },
  {
    name: 'apple',
    url: 'https://music.apple.com/ca/album/where-to-begin-single/1484743224',
  },
  {
    name: 'google-play',
    url:
      'https://play.google.com/store/music/album/Tree_Museum_Where_to_Begin?id=B2xjnstxyxsk54hphd2qpak3v4e',
  },
  {
    name: 'tidal',
    url: 'https://tidal.com/browse/album/120883676',
  },
  {
    name: 'bandcamp',
    url: 'https://treemuseum.bandcamp.com/track/where-to-begin',
  },
  {
    name: 'soundcloud',
    url: 'https://soundcloud.com/treemuseum/where-to-begin',
  },
];

export const svalbard = [
  {
    name: 'spotify',
    url: 'https://open.spotify.com/album/4mX670M127nndnRTf2oee9',
  },
  {
    name: 'apple',
    url: 'https://music.apple.com/us/album/svalbard-ep/1533033272',
  },
  {
    name: 'google-play',
    url:
      'https://play.google.com/store/music/album/Tree_Museum_Svalbard?id=Bbdfeqzgrrfclka7uofmrznopza',
  },
  {
    name: 'tidal',
    url: 'https://tidal.com/browse/album/156267673',
  },
];

export const jesse = [
  {
    name: 'spotify',
    url: 'https://open.spotify.com/album/1igXZN4QlC4abRoXD4ELZR',
  },
  {
    name: 'apple',
    url: 'https://music.apple.com/us/album/jesse-single/1538082676',
  },
  {
    name: 'tidal',
    url: 'https://tidal.com/browse/track/160357492',
  },
];

export const hardlyThere = [
  {
    name: 'spotify',
    url: 'https://open.spotify.com/album/3NCD0L2MUVptHoUHIrLVhE',
  },
  {
    name: 'apple',
    url: 'https://music.apple.com/us/album/hardly-there-single/1621627461',
  },
  {
    name: 'tidal',
    url: 'https://tidal.com/browse/album/226791495',
  },
];
