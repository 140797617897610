import { lifecycle } from 'recompose';

export default (name, fn) =>
  lifecycle({
    async componentDidMount() {
      const result = await fn();
      console.log(name, JSON.stringify(result));
      this.setState({ [name]: result });
    },
  });
