import React from 'react';
import styled from 'styled-components';

export const IconButtonBase = styled.div`
  flex: 1 1 200px;

  display: flex;
  align-content: center;
  justify-content: center;

  margin: 16px 0;
  max-height: 60px;
  line-height: 3em;

  text-decoration: none;
  font-size: 1.2em;
  font-weight: 400;
  letter-spacing: 1px;

  background: black;
  color: white;
  box-shadow: inset 0 0 0 2px #eee;
  transition: all 0.25s ease-in-out;

  span {
    text-align: center;
  }

  i {
    padding: 0 10px;
    align-self: center;
    text-align: center;
    font-size: 1.5em;
  }

  svg {
    fill: white;
    stroke: white;
    transition: all 0.3s ease-in-out;
  }

  &:hover,
  &:active {
    background: white;
    color: black;
    box-shadow: inset 0 0 0 2px transparent;
    cursor: hover;
    svg {
      fill: black;
      stroke: black;
    }
  }
`;

export const IconButton = ({ icon, text, ...rest }) => (
  <IconButtonBase {...rest}>
    {icon}
    <span>{text}</span>
  </IconButtonBase>
);
