import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import NavBlog from './NavBlog';
import AllPosts from './AllPosts';
import PostFull from './PostFull';

const BlogBox = styled.div`
  letter-spacing: 0.2px;
`;

class BlogPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <NavBlog />
        <BlogBox id="main-content">
          <Switch>
            <Route exact path="/blog" component={AllPosts} />
            <Route path="/blog/:slugUrl" component={PostFull} />
          </Switch>
        </BlogBox>
      </div>
    );
  }
}

export default BlogPage;
