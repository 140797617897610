import React from 'react';
import styled from 'styled-components';
import { blogStore } from '../../core/requestUtil';
import Post from './Post';
import { compose, lifecycle, withState } from 'recompose';

const BlogList = styled.div`
  background: black;
  padding: 32px;
  margin: 32px 0;

  @media (max-width: 700px) {
    padding: 24px 16px;
  }
`;

const BlogItem = styled.div`
  padding: 24px 0;
`;

const AllPosts = ({ posts }) => (
  <section className="container blog-container">
    <BlogList>
      <h2>All News</h2>
      {posts.map((post, i) => (
        <BlogItem key={i}>
          <Post post={post} />
        </BlogItem>
      ))}
    </BlogList>
  </section>
);

export default compose(
  withState('posts', 'setPosts', []),
  lifecycle({
    async componentDidMount() {
      const posts = await blogStore.getAll();
      this.props.setPosts(posts);
    },
  })
)(AllPosts);
