import React, { Component } from 'react';
import { Container, ServiceButton } from '../lib';
import styled from 'styled-components';

const H2 = styled.h2`
  padding-top: 32px;
`;

const ListenBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
`;

const ListenStream = styled.div`
  flex: 1 0 400px;
  margin: auto;
  padding-right: 16px;

  @media (max-width: 650px) {
    flex: 1 1 400px;
    padding-right: 0;
  }
`;

const ListenBlocks = styled.div`
  flex: 0 1 400px;
  display: flex;
  flex-direction: column;
  align-content: center;
`;

const sources = [
  {
    name: 'youtube',
    url: 'https://www.youtube.com/channel/UCsXe_1v-b6At5aG9FbSLhOg/featured',
  },
  {
    name: 'bandcamp',
    url: 'https://treemuseum.bandcamp.com/',
  },
  {
    name: 'spotify',
    url: 'https://open.spotify.com/artist/2ZUmP24iJ77uZ1xHoz6pzi',
  },
  {
    name: 'soundcloud',
    url: 'https://soundcloud.com/treemuseum',
  },
  {
    name: 'apple',
    url: 'https://itunes.apple.com/ca/artist/tree-museum/1052604678',
  },
];

class Listen extends Component {
  render() {
    const ListenStyles = {
      border: 0,
      flexGrow: 1,
      flexShrink: 1,
      height: '737px',
    };

    // TODO: why?
    ListenStyles.minWidth = window.innerWidth < 450 ? '100px' : '400px';

    return (
      <Container id="listen">
        <H2>Listen</H2>
        <ListenBox>
          <ListenStream>
            <iframe
              title="spotify-preview"
              style={{ borderRadius: '12px' }}
              src="https://open.spotify.com/embed/artist/2ZUmP24iJ77uZ1xHoz6pzi?utm_source=generator&theme=0"
              width="100%"
              height="380"
              frameBorder="0"
              allowfullscreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            />
          </ListenStream>
          <ListenBlocks>
            {sources.map(({ name, url }, i) => (
              <ServiceButton name={name} url={url} key={i} />
            ))}
          </ListenBlocks>
        </ListenBox>
      </Container>
    );
  }
}

export default Listen;
