import React from 'react';
import {
  compose,
  withHandlers,
  withState,
  branch,
  renderComponent,
} from 'recompose';
import { propEq } from 'ramda';
import smoothScroll from 'smooth-scroll';
import NavHome from './common/NavHome';
import { FeatureBanner, Bio, Listen, Contact } from './common';
import Splash from './pages/SplashMusic';

import { shouldSplash, splashViewed } from '../core/splashUtil';

const Home = () => (
  <div>
    <NavHome />
    <div id="main-content" className="home-page">
      <FeatureBanner />
      <Bio />
      <Listen />
      <Contact />
    </div>
  </div>
);

const onEnter = ({ setShouldSplash }) => () => {
  setShouldSplash(false);
  const scroll = new smoothScroll();
  scroll.animateScroll(0);
  splashViewed();
};

const maybeRenderSplash = branch(
  propEq('shouldSplash', true),
  compose(
    withHandlers({ onEnter }),
    renderComponent(Splash)
  )
);

export default compose(
  withState('shouldSplash', 'setShouldSplash', shouldSplash()),
  maybeRenderSplash
)(Home);
