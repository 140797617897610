import React, { Component } from 'react';
import styled from 'styled-components';
import { Container, Link, IconButton, ServiceButton } from '../lib';
import { Flex } from 'rebass';

const contactEmail = 'contact@treemuseum.ca';
const bookingEmail = 'aarontocheri@gmail.com';
const platforms = [
  {
    name: 'facebook',
    url: 'https://www.facebook.com/treemuseum/',
  },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/tree.museum/',
  },
];
const epkLink =
  'https://firebasestorage.googleapis.com/v0/b/tree-museum.appspot.com/o/TREEMUSEUM-EPK.zip?alt=media&token=b9c6026c-a596-4feb-8e18-634e038d9fca';

const ContactSection = styled.div`
  padding: 16px 0 32px 0;
`;

const ContactContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  > * {
    flex: 0 0 400px;
    align-items: flex-start;
    padding-right: 16px;
  }

  @media (max-width: 650px) {
    > * {
      flex: 1 1 400px;
      margin: auto;
      padding-right: 0;
    }
  }
`;

const H3 = styled.h3`
  margin: 16px 0 0;
`;

const SocialBox = styled(Flex).attrs({ justifyContent: 'space-between' })`
  width: 100%;
  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const SocialButtons = () => (
  <React.Fragment>
    <H3>Social</H3>
    <SocialBox>
      {platforms.map(({ name, url }, i) => (
        <ServiceButton key={i} url={url} name={name} />
      ))}
    </SocialBox>
  </React.Fragment>
);

const EmailSection = () => (
  <div>
    <H3>General Inqueries</H3>
    <Link href={'mailto:' + contactEmail} target="_top" type="link">
      {contactEmail}
    </Link>
    <H3>Booking</H3>
    <p>Aaron Tocheri</p>
    <Link href={'mailto:' + bookingEmail} target="_top" type="link">
      {bookingEmail}
    </Link>
  </div>
);

class Contact extends Component {
  render() {
    return (
      <ContactSection id="contact">
        <Container>
          <h2>Contact & Information</h2>
          <ContactContainer>
            <div>
              <EmailSection />
            </div>
            <div>
              <SocialButtons />
              <H3>EPK</H3>
              <IconButton
                as="a"
                href={epkLink}
                download
                icon={<i className="fa fa-download" />}
                text="Download"
              />
            </div>
          </ContactContainer>
        </Container>
      </ContactSection>
    );
  }
}

export default Contact;
