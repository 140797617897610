import { Button as RebassButton } from 'rebass';
import styled, { css } from 'styled-components';

export const buttonStyles = css`
  font-family: 'Chosence', sans-serif;
  letter-spacing: 1.6px;
  display: inline-block;
  color: ${p => p.theme.colors.onSurface};
  transition: all 0.3s ease-in-out;
  &:hover,
  &:active,
  &:focus {
    background: rgba(255, 255, 255, 1);
    color: black;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const Button = styled(RebassButton).attrs({
  border: 2,
  borderRadius: 0,
  bg: 'background',
  mt: 4,
  px: 4,
  py: 3,
})`
  ${buttonStyles}
`;
