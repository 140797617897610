import React, { Component } from 'react';
import styled from 'styled-components';
import { blogStore } from '../../core/requestUtil';

const Header = styled.h1`
  padding: 18px 0;
`;

const Thing = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 900px;
  min-height: 100vh;

  @media (max-width: 700px) {
    width: 95%;
  }

  background: rgb(22, 22, 22);
  margin: 48px auto;
  padding: 24px 42px;
  overflow: hidden;

  @media (max-width: 700px) {
    padding: 24px 16px;
  }
`;

export const PostBody = styled.p`
  line-height: 1.8rem;
  padding: 8px 0;
  a {
    cursor: pointer;
    margin: 12px 0 0;
    font-size: 14px;
    color: white;

    &:hover {
      color: #ddd;
    }
  }
`;

export default class PostFull extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: {},
    };
  }

  componentDidMount() {
    const { slugUrl } = this.props.match.params;
    blogStore.getOne(slugUrl).then(post => {
      this.setState({ post });
    });
  }

  render() {
    const { post } = this.state;

    return (
      <Thing>
        <Header>{post.title}</Header>
        <PostBody dangerouslySetInnerHTML={{ __html: post.body }} />
      </Thing>
    );
  }
}
