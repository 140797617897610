let _shouldSplash = true;

export function splashViewed() {
  _shouldSplash = false;
}

export function shouldSplash() {
  if (process.env.REACT_APP_SHOULD_SPLASH === 'false') {
    return false;
  }
  return _shouldSplash;
}
