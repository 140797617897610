import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ServiceButton, Button } from '../lib';
import { neonDreams } from '../../data/releases';
import cover from '../../assets/neon-dreams-lores.jpg';

const sources = neonDreams;

const FullHeight = styled.div`
  height: 100vh;
`;

const AlbumSplashBox = styled.section`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
  margin: 5vh auto;
  padding: 20px 20px;
  @media (max-width: 500px) {
    padding: 0 5px;
  }
  @media (max-width: 980px) {
    max-width: 500px;
    margin: 0 auto;
  }
`;

const AlbumImage = styled.div`
  flex: 1 1 400px;
  img {
    padding: 10px 40px;
    width: 100%;
  }
  @media (max-width: 550px) {
    flex: 1 1 200px;
  }
`;

const AlbumInfo = styled.div`
  flex: 1 1 350px;
  text-align: center;
  @media (max-width: 300px) {
    flex: 1 1 100px;
  }
`;

const H2 = styled.h2`
  font-size: 3em;
  font-weight: 300;
`;

const Description = styled.p`
  padding: 0.3em;
  font-size: 1em;
  font-weight: 300;
`;

const H4 = styled.h4`
  padding-top: 16px;
  font-size: 1.5em;
  font-weight: 400;
`;

const ListenBlocks = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
  > * {
    margin: 8px 4px;
  }
`;

const SplashAlbum = ({ onEnter }) => (
  <FullHeight>
    <AlbumSplashBox>
      <AlbumImage>
        <img
          src={cover}
          alt="Album Art - Tree Museum - Neon Friends (Fucked up Friends)"
        />
      </AlbumImage>
      <AlbumInfo>
        <H2>Neon Dreams (Fucked up Friends)</H2>
        <Description>New single available on all platforms</Description>

        <H4>Listen On:</H4>
        <ListenBlocks>
          {sources.map(({ name, url }, i) => (
            <ServiceButton name={name} url={url} key={i} />
          ))}
        </ListenBlocks>
        <Button onClick={onEnter}>Enter Site</Button>
      </AlbumInfo>
    </AlbumSplashBox>
  </FullHeight>
);

SplashAlbum.propTypes = {
  onEnter: PropTypes.func.isRequired,
};

export default SplashAlbum;
