import React from 'react';
import styled from 'styled-components';
import { compose, defaultProps } from 'recompose';
import { branchShouldRequest, withMocks, withRequest } from '../../../helpers';
import { Link, Container } from '../../lib';
import { showStore } from '../../../core/requestUtil';

const ShowsBackground = styled.div`
  background-image: url('https://firebasestorage.googleapis.com/v0/b/tree-museum.appspot.com/o/moose.jpg?alt=media&token=9e2ed090-a68f-4d56-932a-59e015876353');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 650px) {
    width: 100%;
  }
`;

const ShowsWrap = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ShowsInternal = styled.div`
  flex: 1 1 auto;
  height: 70vh;
  padding: 12px 32px;
  background: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: column;
`;

const H2 = styled.h2`
  padding-bottom: 8px;
  border-bottom: 2px solid white;
`;

const ShowsBox = styled.div`
  overflow: scroll;
`;

const ShowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  padding: 18px 2px;
  letter-spacing: 1px;
`;

const DetailsLink = styled(Link)`
  font-size: 12px;
`;

const ShowDate = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  padding: 0 0 8px;
  font-weight: 700;
  text-transform: uppercase;
`;

const Show = ({ date, venue, location, link }) => (
  <ShowContainer>
    <div>
      <ShowDate>{date}</ShowDate>
      <p>{venue}</p>
      <p>{location}</p>
    </div>
    <div>
      <DetailsLink href={link} target="_blank" rel="noopener noreferrer">
        More Details
      </DetailsLink>
    </div>
  </ShowContainer>
);

const Shows = ({ shows }) => (
  <ShowsBackground id="shows">
    <ShowsWrap>
      <ShowsInternal>
        <H2>Upcoming Shows</H2>
        <ShowsBox>
          {shows.map((show, i) => (
            <Show {...show} key={i} />
          ))}
        </ShowsBox>
      </ShowsInternal>
    </ShowsWrap>
  </ShowsBackground>
);

export default compose(
  defaultProps({ shows: [] }),
  branchShouldRequest(
    withRequest('shows', () => showStore.getAll()),
    withMocks('shows', () => require('./mocks.json'))
  )
)(Shows);
