import React from 'react';
import styled from 'styled-components';
import { compose, defaultProps, renderComponent, branch } from 'recompose';
import { branchShouldRequest, withRequest } from '../../helpers';
import { repeat, propSatisfies, isNil, reverse } from 'ramda';
import { ImageCircleIcon } from '../lib';
import { igStore } from '../../core/requestUtil';

const IGPostBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  max-width: 650px;
`;

const IGPost = styled.div`
  flex: 0 0 30%;
  margin: 5px 14px 5px 0;
  img {
    margin: auto;
    width: 100%;
  }
  @media (max-width: 650px) {
    flex: 0 0 28%;
  }
`;

const PlaceholderPost = styled(IGPostBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 120px;
  background: rgba(255, 255, 255, 0.1);
`;

const PlaceHolderImage = () => (
  <PlaceholderPost>
    <ImageCircleIcon />
  </PlaceholderPost>
);

const InstagramImage = ({ shortUrl }) => (
  <img
    src={`https://instagram.com/p/${shortUrl}/media/?size=t`}
    alt="tree museum instagram post"
  />
);

const EnhancedIGImage = compose(
  branch(propSatisfies(isNil, 'shortUrl'), renderComponent(PlaceHolderImage))
)(InstagramImage);

const InstagramPosts = ({ posts }) => (
  <IGPostBox>
    {posts.map((post, index) => (
      <IGPost key={index}>
        <a href={post.url}>
          <EnhancedIGImage shortUrl={post.shortUrl} />
        </a>
      </IGPost>
    ))}
  </IGPostBox>
);

export default compose(
  defaultProps({ posts: repeat({ shortUrl: null }, 9) }),
  branchShouldRequest(
    withRequest('posts', async () => {
      const posts = await igStore.getAll();
      return reverse(posts);
    })
  )
)(InstagramPosts);
