import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const height = '70px';

const NavBar = styled.nav`
  background: #000;
  color: white;
  position: fixed;
  overflow: hidden;
  top: 0;
  width: 100%;
  border-bottom: 1px solid rgb(12, 12, 12);
  z-index: 2;
`;

const NavBox = styled.div`
  display: flex;
  margin: auto;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 85%;
  max-width: 900px;

  h1 {
    text-align: center;
    line-height: ${height};
    font-weight: 200;
    font-size: 22px;

    span:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 650px) {
    width: 100%;
    justify-content: center;

    h1 {
      flex: 0 1 650px;
      line-height: 40px;
    }
  }
`;

const NavLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  max-width: 480px;

  @media (max-width: 650px) {
    justify-content: space-between;
    max-width: 100%;
  }
`;

const NavLink = styled(Link)`
  font-family: 'Chosence', sans-serif;
  background: transparent;
  border: 0;
  line-height: ${height};
  padding: 0 8px;
  font-size: 16px;
  letter-spacing: 2.5px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  transition: background 0.2s ease-in-out;
  font-weight: 400;
  color: white;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 650px) {
    line-height: 40px;
    font-size: 14px;
    letter-spacing: 2px;
    padding: 0;
  }
`;

export default class NavBlog extends Component {
  render() {
    return (
      <NavBar>
        <NavBox>
          <h1>Tree Museum Blog</h1>
          <NavLinks>
            <NavLink to={'/'}>Home</NavLink>
            <NavLink to={'/blog'}>All News</NavLink>
          </NavLinks>
        </NavBox>
      </NavBar>
    );
  }
}
