const URL = 'https://us-central1-tree-museum.cloudfunctions.net/api/';
const SHOULD_REQUEST = true;

function get(url) {
  if (SHOULD_REQUEST) {
    return fetch(url).then(res => res.json());
  } else {
    return Promise.resolve();
  }
}

// base class for managing a list of api data
// keeps a simple cache of data to prevent further requests
class List {
  constructor(data) {
    this.name = data.name;
    this.endpoint = URL + data.name;
    this.list = [];
  }

  getAll() {
    if (this.list.length) {
      return Promise.resolve(this.list);
    } else {
      return get(URL + this.name).then(res => {
        this.list = res || [];
        return this.list;
      });
    }
  }
}

class ListWithSingle extends List {
  constructor(data) {
    super(data);
    this.uid = data.uid;
  }

  getOne(uid) {
    const existing = this.list.filter(item => item[this.uid] === uid);

    if (existing.length) {
      return Promise.resolve(existing[0]);
    } else {
      const url = `${URL}${this.name}/${uid}`;
      return get(url).then(result => result || {});
    }
  }
}

export const blogStore = new ListWithSingle({
  name: 'news',
  uid: 'slugUrl',
});

export const showStore = new List({
  name: 'shows',
});

export const igStore = new List({
  name: 'ig',
});
