import { createGlobalStyle } from 'styled-components';
import Font from '../assets/Chosence-Light.otf';

const forecolor = '#fafafa';
const mobileBreak = '650px';

export default createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

    @font-face {
        font-family: 'Chosence';
        font-style: normal;
        font-weight: normal;
        src: url(${Font}) format('truetype');
    }


    * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    }

    body {
    font-family: 'Roboto', sans-serif;
    color: ${forecolor};
    background: transparent;
    font-size: 16px;
    font-weight: 200;

    background: black;
    }

    h1,
    h2,
    h3,
    a {
    font-family: 'Chosence', sans-serif;
    letter-spacing: 1.6px;
    }

    h2 {
    padding: 16px 0;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: 1.2;
    }

    h3 {
    font-size: 18px;
    padding: 0 0 8px;
    font-weight: 500;
    }

    .link {
        color: rgba(235, 210, 210, 0.8);

        &:hover {
            color: rgba(235, 210, 210, 0.9);
            text-decoration: underline;
        }
    }

    /* LAYOUT */

    

    #main-content {
    padding-top: 70px;

    @media(max-width: ${mobileBreak}) {
        padding-top: 80px;
    }
    }

    .container {
    margin: 0 auto;
    width: 90%;
    max-width: 900px;
    min-height: 100vh;
    }


    .background-image {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -5;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/tree-museum.appspot.com/o/band.jpg?alt=media&token=050915dc-86ad-4c67-b3c7-13afc7b42099");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    }
`;
